var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('questions-batch-upload-modal',{on:{"cancelUpload":_vm.cancelModal,"refresh":function($event){return _vm.refresh()}},model:{value:(_vm.batchUploadModal),callback:function ($$v) {_vm.batchUploadModal=$$v},expression:"batchUploadModal"}}),_c('Card',{attrs:{"dis-hover":""}},[_c('div',{staticStyle:{"display":"flex"},attrs:{"slot":"title"},slot:"title"},[_c('p',{staticClass:"question-num-text"},[_vm._v("题号")]),(_vm.management === false)?_c('div',[_c('i-switch',{staticClass:"changeReviewSwitch",attrs:{"false-color":"#338cf0","true-color":"#40cc99","before-change":_vm.changeReview},on:{"refresh":function($event){return _vm.refresh()}},model:{value:(_vm.reviewSw),callback:function ($$v) {_vm.reviewSw=$$v},expression:"reviewSw"}},[_c('span',{attrs:{"slot":"close"},slot:"close"},[_vm._v("刷题")]),_c('span',{attrs:{"slot":"open"},slot:"open"},[_vm._v("检查")])])],1):_c('div',{staticClass:"batch-upload"},[_c('Button',{attrs:{"type":"text","size":"small","icon":"ios-cloud-upload-outline"},on:{"click":_vm.uploadDocument}},[_vm._v("批量上传 ")])],1)]),_c('div',{staticClass:"questions-table add-border"},[_c('Row',{attrs:{"gutter":4}},[_vm._l((_vm.questionIdList),function(questionId){return _c('Col',{key:questionId,staticStyle:{"text-align":"center"},attrs:{"xl":4,"lg":6}},[_c('Button',{class:{
              'little-que-btn': true,
              'finished-que': _vm.questionIdFinished.indexOf(questionId) !== -1,
              'correct-que': _vm.isCorrect(questionId) === true && _vm.review == true,
              'wrong-que': _vm.isCorrect(questionId) === false && _vm.review == true,
              selected: questionId == _vm.currentId
            },on:{"click":function($event){return _vm.jump(questionId)}}},[_c('p',{staticClass:"little-que-index"},[_vm._v(" "+_vm._s(_vm.questionIdList.indexOf(questionId) + 1)+" ")])])],1)}),(_vm.management === true)?_c('Col',{staticStyle:{"text-align":"center"},attrs:{"xl":4,"lg":6}},[(_vm.privilege > 1)?_c('Button',{class:{ 'little-que-btn': true },on:{"click":function($event){return _vm.addNewQuestion()}}},[_c('Icon',{attrs:{"type":"md-add"}})],1):_vm._e()],1):_vm._e()],2)],1),_c('Row',{attrs:{"justify":"end"}},[_c('Button',{attrs:{"type":"error"},on:{"click":() => {
            this.deleteFinished = true;
          }}},[_vm._v(" 清空刷题记录 ")])],1)],1),_c('Modal',{on:{"on-ok":_vm.ok,"on-cancel":_vm.cancel},model:{value:(_vm.doubleCheck),callback:function ($$v) {_vm.doubleCheck=$$v},expression:"doubleCheck"}},[_vm._v(" Review模式下将默认展开所有题目的答案，是否确认开启？ ")]),_c('Modal',{attrs:{"width":"360"},model:{value:(_vm.deleteFinished),callback:function ($$v) {_vm.deleteFinished=$$v},expression:"deleteFinished"}},[_c('p',{staticStyle:{"color":"#f60","text-align":"center"},attrs:{"slot":"header"},slot:"header"},[_c('Icon',{attrs:{"type":"ios-information-circle"}}),_c('span',[_vm._v("删除确认")])],1),_c('div',{staticStyle:{"text-align":"center"}},[_c('p',[_vm._v("确认删除后，会清空您的做题记录")]),_c('p',[_vm._v("是否从删除?")])]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{attrs:{"type":"error","size":"large","long":""},on:{"click":_vm.deleteFinishedList}},[_vm._v("Delete")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }