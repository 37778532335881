<style lang="less" scoped>
@import "./questions-batch-upload-modal.less";
</style>

<template>
  <Modal
    v-bind:value="value"
    class="attachment-upload-modal"
    width="650px"
    v-if="true"
    :closable="false"
    :mask-closable="false"
  >
    <div class="header">
      <div
        class="ivu-modal-confirm-head-icon ivu-modal-confirm-head-icon-confirm"
      >
        <Icon type="md-cloud-upload" />
      </div>
      <div class="header-text ivu-modal-confirm-head-title">批量上传题目</div>
    </div>
    <div>
      <div class="modal-subtitle">格式介绍:</div>
      <Card dis-hover>
        <p>列名：题型，试题标题，题目，选项A...F，正确答案，解析。</p>
        <p>题型：“1”为单选题，“2”为多选题，“3”为判断题，“4”为填空题。</p>
        <p>试题标题、题目以及解析：文本信息，请填写在一个单元格内。</p>
        <p>
          选项设置：选项不为空；选择题每一项选项单独占一格直接填写选项内容即可，选择题和判断题都必须有选项。
        </p>
        <p>
          正确答案：选择题答案不为空；多选题正确答案不隔开（ABD）；判断题答案A为正确
          B为错误；填空题答案可为空；多个答案中间不需要连接符（注意空格）。
        </p>
      </Card>
    </div>
    <div>
      <div class="modal-subtitle">文件上传:</div>
      <Upload
        action="#"
        v-if="percent !== 100"
        accept=".xls, .xlsx"
        type="drag"
        :show-upload-list="false"
        :max-size="51200"
        :format="['xls', 'xlsx']"
        :before-upload="handleBeforeUpload"
        :on-format-error="handleFormatError"
        :on-exceeded-size="handleMaxSize"
      >
        <div class="upload-center-image" v-if="percent !== 100">
          <Icon
            type="ios-cloud-upload-outline"
            size="50"
            style="color: #3399ff"
          />
          <p>点击上传文件</p>
        </div>
      </Upload>
      <div>
        <p>
          {{ filename == "" ? "暂无文件" : filename }}
        </p>
        <Progress :percent="percent" :stroke-width="18" text-inside />
      </div>
    </div>
    <p class="notice">*上传文件格式仅限xls和xlsx，目前文件大小仅支持50M以内</p>
    <div slot="footer">
      <Button @click="onCancel" v-if="percent !== 100">取消</Button>
      <Button @click="onOk" type="primary">确定</Button>
    </div>
  </Modal>
</template>
<script>
import { Upload } from "view-design";
export default {
  name: "QuestionsBatchUploadModal",
  props: ["value"],
  computed: {
    modal_title: {
      get: function () {
        return this.getVideoTitle(this.courseRawText);
      }
    },
    modal_link: {
      get: function () {
        return this.getVideoLink(this.courseRawText);
      }
    }
  },
  data: function () {
    return {
      filename: "",
      percent: 0,

      uploadedSingleQuesNum: 0,
      uploadedMultiQuesNum: 0,
      uploadedBinaryQuesNum: 0,
      uploadedShortQuesNum: 0,
      successUploadedQuesNum: 0,
      successAddQuesNum: 0
    };
  },
  methods: {
    handleBeforeUpload(file) {
      const form = new FormData();
      this.filename = file.name;
      form.append("name", file.name);
      form.append("file", file);

      this.axios
        .post(
          `api/campus/${this.$route.query.campus}/faculty/${this.$route.query.faculty}/unit/${this.$route.query.course}/question/batch_upload`,
          form,
          {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: evt => {
              let percent = 0;
              if (evt.lengthComputable) {
                percent = Math.floor((evt.loaded / event.total) * 100);
                if (percent > 100) {
                  percent = 100;
                }
              }
              this.percent = percent;
            }
          }
        )
        .then(res => {
          this.uploadedSingleQuesNum = res.data.result[1];
          this.uploadedMultiQuesNum = res.data.result[2];
          this.uploadedBinaryQuesNum = res.data.result[3];
          this.uploadedShortQuesNum = res.data.result[4];
          this.successUploadedQuesNum = res.data.questions_found;
          this.successAddQuesNum = res.data.questions_added;
          this.onOk();
          return false;
        })
        .catch(error => {
          this.$Message.error({
            content: `文件上传失败！错误信息: ${error.response.data.message}`,
            duration: 4
          });
          this.$Modal.remove();
          this.$emit("cancelUpload");
          this.filename = "";
          this.percent = 0;
          return false;
        });
      return false;
    },
    handleFormatError() {
      this.$Message.warning("上传文件格式不正确，请上传 .xls .xlsx 文件！");
      this.$Modal.remove();
      this.filename = "";
      this.percent = 0;
      this.$emit("cancelUpload");
    },
    handleMaxSize() {
      this.$Message.warning("文件大小超出限制，最大文件限制为50MB！");
      this.$Modal.remove();
      this.filename = "";
      this.percent = 0;
      this.$emit("cancelUpload");
    },

    get_suffix(filename) {
      const pos = filename.lastIndexOf(".");
      let suffix = "";
      if (pos != -1) {
        suffix = filename.substring(pos);
      }
      return suffix;
    },
    calculate_key(suffix, baseInfo) {
      return baseInfo.dir + baseInfo.file_uuid + suffix;
    },
    onOk() {
      if (this.percent !== 100) {
        return this.$Message.warning("请选择文件！");
      }
      this.$Modal.success({
        title: "上传成功",
        content: `<p>共识别到<b>${
          this.successUploadedQuesNum
        }</b>道题目，已成功添加<b>${this.successAddQuesNum}</b>道题目。
                  <br>
                  已成功上传： <br>
                    单选题: <b>${
                      this.uploadedSingleQuesNum
                        ? this.uploadedSingleQuesNum
                        : 0
                    }</b>道<br>
                    多选题: <b>${
                      this.uploadedMultiQuesNum ? this.uploadedMultiQuesNum : 0
                    }</b>道<br>
                    判断题: <b>${
                      this.uploadedBinaryQuesNum
                        ? this.uploadedBinaryQuesNum
                        : 0
                    }</b>道<br>
                    简答题: <b>${
                      this.uploadedShortQuesNum ? this.uploadedShortQuesNum : 0
                    }</b>道</p>`,
        onOk: () => {
          this.filename = "";
          this.percent = 0;
          this.$emit("refresh");
        }
      });
    },
    onCancel() {
      this.$Modal.remove();
      this.$emit("cancelUpload");
    }
  }
};
</script>
