<style lang="less" scoped>
@import "./Question.less";
</style>

<template>
  <div>
    <div class="que-bank-cont">
      <Row>
        <Col class="que-bank-cont-body" span="17">
          <search-questions
            @management="changeManagement($event)"
          ></search-questions>

          <question-manage
            v-if="
              (questionInfo || newQue === true) &&
              privilege > 1 &&
              management === true &&
              course
            "
            :questions="questionInfo"
            :courseCode="selectedCourse"
            :totalQuestions="totalQuestions"
            :currentIndex="currentIndex"
            :creatingQuestion="newQue"
            @delQuestion="delQuestion()"
            @newQuestion="addNewQuestion($event)"
            @cancelCreation="cancelCreation()"
          ></question-manage>

          <question-practice
            v-if="questionInfo && management === false && course && pageLoaded"
            :question="questionInfo"
            :totalQuestion="totalQuestions"
            :currentQuestion="currentIndex"
            :questionIdList="questionIdList"
            :newReview="review"
            v-on:finishQuestion="addFinishQuestion($event)"
          ></question-practice>
        </Col>

        <Col class="que-bank-cont-slider" span="7">
          <que-number
            v-if="course"
            :questionIdList="questionIdList"
            :currentId="currentId"
            :questionIdFinished="questionIdFinished"
            :management="management"
            v-on:changeReview="changeReview($event)"
            @addQuestion="addQuestion()"
            @refresh="refresh()"
          ></que-number>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SearchQuestions from "../../components/questions-search/search-questions.vue";
import QuestionManage from "../../components/que-stem-manage/que-stem-manage.vue";
import QueNumber from "../../components/que-number/que-number.vue";
import QuestionPractice from "../../components/questions-practice/question-practice.vue";
import { loadExtraFile } from "../../lib/extra.js";

export default {
  components: {
    SearchQuestions,
    QuestionManage,
    QueNumber,
    QuestionPractice
  },
  data() {
    return {
      pageLoaded: false,
      selectedCourse: "",
      //题目分页
      page: 1,
      // 当前题目ID
      currentId: "",
      //当前页数的题目
      questionsPage: [],
      //当前题目信息
      questionInfo: null,
      //总题数
      totalQuestions: 0,
      //所有题目的ID列表
      questionIdList: [],
      newQue: false,
      questionIdFinished: [],
      currentIndex: 1,
      management: false,
      review: false
    };
  },
  methods: {
    changeReview(val) {
      this.review = val;
    },

    fetchQuestions(courseCode) {
      //获取题目ID list
      let id;
      this.selectedCourse = courseCode;
      if (this.selectedCourse) {
        this.$Loading.start();
        this.axios
          .get(
            `api/campus/${this.campus}/faculty/${this.faculty}/unit/${courseCode}/question/ids`
          )
          .then(res => {
            this.$Loading.finish();
            this.questionIdList = res.data;
            if (res.data.length === 0) {
              this.$Message.warning("该题目暂时没有题目！");
            }
            if (this.questionIdList.length === 0) {
              this.questionInfo = null;
            }

            if (this.id) {
              //如果当前id在list中
              if (this.questionIdList.includes(this.id) == true) {
                id = this.id;
                this.moveQuestion(this.id);
              } else {
                // 改变课程
                id = this.questionIdList[0];
                this.$router.push({
                  query: {
                    campus: this.campus,
                    faculty: this.$route.query.faculty,
                    course: courseCode,
                    id: id
                  }
                });
              }
            } else {
              id = this.questionIdList[0];
              //跳转到第一题的url
              this.$router.push({
                query: {
                  campus: this.campus,
                  faculty: this.$route.query.faculty,
                  course: courseCode,
                  id: id
                }
              });
            }
            this.currentId = id;
            this.totalQuestions = this.questionIdList.length;
          })
          .catch(error => {
            this.$Loading.error();
            this.$Message.error("获得题目信息失败");
          });
        this.axios
          .get(
            `/api/campus/${this.campus}/faculty/${this.faculty}/unit/${this.course}/question/finished`
          )
          .then(res => {
            this.questionIdFinished = res.data;
          });
      }
    },

    //删除题目，重新渲染
    delQuestion: function () {
      // 判断有无题目
      if (this.selectedCourse) {
        this.fetchQuestions(this.selectedCourse);
      }
    },

    //新建题目
    addQuestion: function () {
      this.newQue = true;
    },

    addNewQuestion: function (newQuestionId) {
      if (this.selectedCourse) {
        this.$Loading.start();
        this.axios
          .get(
            `/api/campus/${this.campus}/faculty/${this.faculty}/unit/${this.selectedCourse}/question/ids`
          )
          .then(res => {
            this.$Loading.finish();
            this.questionIdList = res.data;
            this.currentId = newQuestionId;
            this.changeCurrentId(this.currentId);
            this.totalQuestions = this.questionIdList.length;
            this.newQue = false;
          })
          .catch(error => {
            this.$Loading.error();
            this.$Message.error("获得题目信息失败");
          });
      } else {
        this.$Loading.error();
        this.$Message.warning("请选择课程！");
      }
    },

    //取消新建
    cancelCreation: function () {
      this.newQue = false;
    },

    changeCurrentId: function (newQuestionId) {
      this.$router.push({
        path: "/question",
        query: {
          campus: this.campus,
          faculty: this.$route.query.faculty,
          course: this.selectedCourse,
          id: newQuestionId
        }
      });
      this.moveQuestion(newQuestionId);
    },

    moveQuestion: function (id) {
      this.currentId = id;
      this.currentIndex = this.questionIdList.indexOf(id) + 1;
      //在vuex中查找ID
      //如果vuex有需要的题
      if (this.QuestionsList.questions[this.currentId]) {
        this.questionInfo = this.QuestionsList.questions[this.currentId];
      } else {
        //在后台获取对应的页数
        this.page = Math.ceil(
          (this.questionIdList.indexOf(this.currentId) + 1) / 5
        );
        //获取分页信息
        if (this.page > 0) {
          this.$Loading.start();
          this.axios
            .get(
              `/api/campus/${this.campus}/faculty/${this.faculty}/unit/${this.selectedCourse}/questions?page=${this.page}`
            )
            .then(res => {
              this.$Loading.finish();
              this.questionsPage = res.data.items;
              //添加题目到vuex
              for (const question of this.questionsPage) {
                this.$store.commit("addQuestions", {
                  id: question.question_id,
                  item: question
                });
              }
              this.questionInfo = this.QuestionsList.questions[this.currentId];
              //this.$Message.success("获取题目信息成功！");
            })
            .catch(error => {
              this.$Loading.error();
              if (this.totalQuestions == 0) {
                this.$Message.error("暂无题目");
              } else {
                this.$Message.error("获取题目信息失败");
              }
            });
        } else {
          this.$Message.error("暂无题目");
        }
      }
    },

    addFinishQuestion: function (finishedQuestionId) {
      this.questionIdFinished.push(finishedQuestionId);
    },

    changeManagement: function (state) {
      this.management = state;
    },

    refresh() {
      this.fetchQuestions(this.course);
    }
  },
  mounted() {
    loadExtraFile(
      "https://cdnjs.cloudflare.com/ajax/libs/KaTeX/0.8.3/katex.min.css"
    );
    const script = loadExtraFile(
      "https://cdnjs.cloudflare.com/ajax/libs/KaTeX/0.8.3/katex.min.js"
    );
    if (script == null) {
      this.pageLoaded = true;
    } else {
      script.addEventListener("load", () => {
        this.pageLoaded = true;
      });
    }
  },

  computed: mapState({
    QuestionsList: state => state.QuestionsList,
    UserData: state => state.UserData,

    id: function () {
      return this.$route.query.id;
    },

    faculty: function () {
      return this.$route.query.faculty;
    },

    course: function () {
      return this.$route.query.course;
    },

    campus: function () {
      const userCampus = sessionStorage.getItem("user_campus");
      return userCampus;
    },

    privilege: function () {
      return this.UserData.user.privilege;
    }
  }),
  watch: {
    course: function () {
      if (this.course && !this.id) {
        this.fetchQuestions(this.course);
      }
    },

    id: {
      immediate: true,
      handler: function () {
        if (this.id) {
          if (this.questionIdList.length === 0) {
            this.fetchQuestions(this.course);
          } else {
            this.moveQuestion(this.id);
          }
        }
      }
    },

    management: function (newVal, oldVal) {
      if (newVal === false && oldVal === true) {
        this.fetchQuestions(this.course);
      }
    }
  }
};
</script>
