<style lang="less" scoped>
@import "./SearchQuestions.less";
</style>

<template>
  <div>
    <div>
      <Row type="flex" justify="space-between" class="code-row-bg">
        <Col span="6">
          <Select
            style="width: 90%"
            placeholder="请选择学校"
            v-model="this.campus"
            disabled
          >
            <Option :value="this.campus">{{ this.campus }} </Option>
          </Select>
        </Col>

        <Col span="6">
          <Select style="width: 90%" v-model="faculty" placeholder="请选择学院">
            <Option v-for="item in facultyList" :value="item" :key="item"
              >{{ item }}
            </Option>
          </Select>
        </Col>

        <Col span="6">
          <Select
            style="width: 90%"
            v-model="course"
            placeholder="请选择科目"
            filterable
            :filter-by-label="true"
          >
            <Option
              v-for="(val, key) in courseList"
              :value="key"
              :label="key"
              :key="key"
            >
              {{ key }}
              <span style="float: right; color: #ccc">{{ val }}</span>
            </Option>
          </Select>
        </Col>
        <Col span="6">
          <Button
            long
            class="begin-btn"
            type="info"
            v-if="this.privilege > 1 && management === true"
            @click="changeBtn"
          >
            返回刷题
          </Button>

          <Button
            type="primary"
            class="begin-btn"
            @click="changeBtn"
            v-if="this.privilege > 1 && management === false"
          >
            题目管理
          </Button>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "SearchQuestions",
  data() {
    return {
      facultyList: [],
      courseList: {},
      management: false,
      campus: ""
    };
  },
  props: [],

  computed: {
    privilege: function () {
      return this.$store.state.UserData.user.privilege;
    },

    faculty: {
      get: function () {
        return this.$route.query.faculty;
      },
      set: function (newVal, oldVal) {
        this.$router.push({
          path: "/question",
          query: {
            campus: this.campus,
            faculty: newVal
          }
        });
      }
    },

    course: {
      get: function () {
        return this.$route.query.course;
      },
      set: function (newVal, oldVal) {
        if (newVal === oldVal) {
          return;
        }
        this.$router.push({
          path: "/question",
          query: {
            campus: this.$route.query.campus,
            faculty: this.$route.query.faculty,
            course: newVal
          }
        });
      }
    },
    id: function () {
      return this.$route.query.id;
    }
  },

  methods: {
    //获取课程IDList
    handleFacultySelected(selectedFaculty) {
      if (!this.$route.query.campus) {
        return "";
      }
      this.$Loading.start();
      this.axios
        .get(
          `api/campus/${this.$route.query.campus}/faculty/${selectedFaculty}/units`
        )
        .then(res => {
          this.$Loading.finish();
          this.courseList = res.data;
        })
        .catch(error => {
          this.$Loading.error();
          this.$Message.error("获取课程信息失败！");
        });
    },

    changeBtn() {
      this.management = !this.management;
      this.$emit("management", this.management);
    },

    getFacultyList() {
      // 获得学院信息
      this.$Loading.start();
      this.axios
        .get(`/api/campus/${this.campus}/faculties`)
        .then(res => {
          this.$Loading.finish();
          this.facultyList = res.data;
        })
        .catch(error => {
          this.$Loading.error();
          this.$Message.error("获取学院信息失败！");
        });
    }
  },
  mounted: function () {
    if (this.$route.query.campus) {
      this.campus = this.$route.query.campus;
    } else this.campus = sessionStorage.getItem("user_campus");
    this.getFacultyList();
  },
  watch: {
    faculty: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.handleFacultySelected(this.$route.query.faculty);
        this.management = false;
      }
    },
    id: {
      immediate: true,
      handler: function () {
        if (this.faculty && Object.keys(this.courseList).length === 0) {
          this.handleFacultySelected(this.faculty);
        }
      }
    }
  }
};
</script>
