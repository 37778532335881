<style lang="less" scoped>
@import "./que-number.less";
</style>

<template>
  <div>
    <questions-batch-upload-modal
      v-model="batchUploadModal"
      @cancelUpload="cancelModal"
      @refresh="refresh()"
    ></questions-batch-upload-modal>

    <Card dis-hover>
      <div slot="title" style="display: flex">
        <p class="question-num-text">题号</p>
        <div v-if="management === false">
          <i-switch
            v-model="reviewSw"
            class="changeReviewSwitch"
            false-color="#338cf0"
            true-color="#40cc99"
            :before-change="changeReview"
            @refresh="refresh()"
          >
            <span slot="close">刷题</span>
            <span slot="open">检查</span>
          </i-switch>
        </div>
        <div v-else class="batch-upload">
          <Button
            type="text"
            size="small"
            icon="ios-cloud-upload-outline"
            @click="uploadDocument"
            >批量上传
          </Button>
        </div>
      </div>
      <div class="questions-table add-border">
        <Row :gutter="4">
          <Col
            :xl="4"
            :lg="6"
            v-for="questionId in questionIdList"
            :key="questionId"
            style="text-align: center"
          >
            <Button
              :class="{
                'little-que-btn': true,
                'finished-que': questionIdFinished.indexOf(questionId) !== -1,
                'correct-que': isCorrect(questionId) === true && review == true,
                'wrong-que': isCorrect(questionId) === false && review == true,
                selected: questionId == currentId
              }"
              @click="jump(questionId)"
            >
              <p class="little-que-index">
                {{ questionIdList.indexOf(questionId) + 1 }}
              </p>
            </Button>
          </Col>
          <Col
            :xl="4"
            :lg="6"
            style="text-align: center"
            v-if="management === true"
          >
            <Button
              :class="{ 'little-que-btn': true }"
              v-if="privilege > 1"
              @click="addNewQuestion()"
            >
              <Icon type="md-add" />
            </Button>
          </Col>
        </Row>
      </div>
      <Row justify="end">
        <Button
          type="error"
          @click="
            () => {
              this.deleteFinished = true;
            }
          "
        >
          清空刷题记录
        </Button>
      </Row>
    </Card>
    <Modal v-model="doubleCheck" @on-ok="ok" @on-cancel="cancel">
      Review模式下将默认展开所有题目的答案，是否确认开启？
    </Modal>

    <Modal v-model="deleteFinished" width="360">
      <p slot="header" style="color: #f60; text-align: center">
        <Icon type="ios-information-circle"></Icon>
        <span>删除确认</span>
      </p>
      <div style="text-align: center">
        <p>确认删除后，会清空您的做题记录</p>
        <p>是否从删除?</p>
      </div>
      <div slot="footer">
        <Button type="error" size="large" long @click="deleteFinishedList"
          >Delete</Button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import QuestionsBatchUploadModal from "../../components/questions-batch-upload-modal";
export default {
  components: {
    QuestionsBatchUploadModal
  },
  props: ["questionIdList", "currentId", "questionIdFinished", "management"],

  computed: {
    ...mapState({
      questionAnswer: state => state.QuestionAnswer.data
    }),

    correct: function () {
      if (Object.keys(this.questionAnswer).includes(this.currentId)) {
        return this.questionAnswer[this.currentId][1];
      }
      return "no answer";
    },

    privilege: function () {
      return this.$store.state.UserData.user.privilege;
    },
    campus: function () {
      return this.$store.getters.getCurrentUser.campus;
    },
    course: function () {
      return this.$route.query.course || "";
    }
  },

  data() {
    return {
      review: false,
      reviewSw: false,
      doubleCheck: false,
      batchUploadModal: false,
      deleteFinished: false
    };
  },
  watch: {
    course() {
      this.reviewSw = false;
      this.review = false;
    }
  },
  methods: {
    jump(questionId) {
      this.$router.replace({
        query: {
          campus: this.campus,
          faculty: this.$route.query.faculty,
          course: this.$route.query.course,
          id: questionId
        }
      });
    },

    isCorrect(questionId) {
      if (Object.keys(this.questionAnswer).includes(questionId)) {
        return this.questionAnswer[questionId][1];
      }
      return "no answer";
    },

    addNewQuestion() {
      this.$emit("addQuestion");
    },

    changeReview() {
      if (this.reviewSw == false) {
        this.doubleCheck = true;
      } else {
        this.$Message.info("进入刷题模式");
        this.review = false;
        this.$emit("changeReview", false);
      }
    },

    ok() {
      this.review = true;
      this.$emit("changeReview", true);
      this.$Message.info("开启review模式");
    },
    refresh() {
      this.batchUploadModal = false;
      this.$emit("refresh");
    },

    cancel() {
      this.review = false;
      this.reviewSw = false;
    },

    handleFormatError() {
      this.$Message.warning("上传文件格式不正确，请上传 .xls .xlsx 文件！");
    },
    handleMaxSize() {
      this.$Message.warning("文件大小超出限制，最大文件限制为50MB！");
    },

    uploadDocument() {
      this.batchUploadModal = true;
    },
    cancelModal() {
      this.batchUploadModal = false;
    },

    deleteFinishedList() {
      this.axios
        .delete(
          `/api/campus/${this.$route.query.campus}/faculty/${this.$route.query.faculty}/unit/${this.$route.query.course}/question/finished`
        )
        .then(res => {
          this.deleteFinished = false;
          this.$store.commit("QuestionAnswer/clearData");
          this.$Message.success("成功清除做题记录！");
        })
        .catch(error => {
          this.deleteFinished = false;
          this.$Message.error("清除做题记录失败！");
        });
      this.deleteFinished = false;
      this.$emit("refresh");
    }
  }
};
</script>
