export function loadExtraFile(src) {
  //创建节点并添加
  const t = src.split(".").reverse()[0]; //获取后缀
  let n = null;
  let check = null;
  if (t == "js") {
    check = document.querySelectorAll("script[src='" + src + "']");
    if (check.length > 0) {
      return;
    }
    n = document.createElement("script");
    n.setAttribute("type", "text/javascript");
    n.setAttribute("src", src);
  } else if (t == "css") {
    check = document.querySelectorAll("link[href='" + src + "']");
    if (check.length > 0) {
      return;
    }
    n = document.createElement("link");
    n.setAttribute("rel", "stylesheet");
    n.setAttribute("href", src);
  }
  if (n != null) {
    document.head.appendChild(n);
  }
  return n;
}
