<style lang="less" scoped>
@import "./question-practice.less";
</style>

<template>
  <div class="question-practice">
    <Row class="type-labels" type="flex" justify="space-between">
      <Col>
        <p v-if="question.item.question_type === 'short answer'">
          {{ "题目类型：简答题" }}
        </p>
        <p v-if="question.item.question_type === 'multiple choice'">
          <span v-if="question.item.is_multiple_answers === true">{{
            "题目类型：多选题"
          }}</span>
          <span v-if="question.item.is_multiple_answers === false">{{
            "题目类型：单选题"
          }}</span>
        </p>
      </Col>
      <!-- <Col span = "6">
        <Button
          type="success"
          @click="submit(true)"
          v-if="review == true"
          long
          >{{ "Check Answers" }}
        </Button>

        <Button
          type="primary"
          @click="submit(false)"
          v-if="review == false"
          long
          >{{ "Practice" }}
        </Button>
      </Col> -->
    </Row>

    <div class="title-count">
      <p>题目：{{ currentQuestion }}/{{ totalQuestion }}</p>
    </div>

    <!-- 题干 -->
    <div>
      <mavon-editor
        v-model="question.item.question_body"
        :subfield="descViewOption.subfield"
        :defaultOpen="descViewOption.defaultOpen"
        :toolbarsFlag="descViewOption.toolbarsFlag"
        :editable="descViewOption.editable"
        :scrollStyle="descViewOption.scrollStyle"
        :boxShadow="descViewOption.boxShadow"
        :previewBackground="descViewOption.previewBackground"
        :ishljs="true"
        :codeStyle="descViewOption.codeStyle"
        style="z-index: 1"
      ></mavon-editor>
    </div>

    <div class="student-answer">
      <Row v-if="question.item.question_type == 'short answer'">
        <Input
          type="textarea"
          placeholder="请输入你的答案"
          v-model="singleChoiceAnswer"
          :rows="4"
        />
      </Row>
      <!-- 多选题选项 -->
      <Row
        v-if="
          question.item.question_type == 'multiple choice' &&
          question.item.is_multiple_answers === true
        "
      >
        <Col span="18">
          <CheckboxGroup
            v-model="answerList"
            v-for="option in question.item.options_list"
            :key="option.key"
          >
            <Checkbox
              :key="option.key + question.id"
              :label="option.key"
              class="multiple-choice-options"
            >
              <span>{{ option.key + ". " }}</span>
              <span v-html="renderKatex(option.body)"></span>
              <div>
                <img
                  class="option-img"
                  v-if="option.image && option.imageUrl"
                  v-bind:src="option.imageUrl"
                />
                <img
                  class="option-img"
                  v-if="option.image && !option.imageUrl"
                  v-bind:src="option.image"
                />
              </div>
            </Checkbox>
          </CheckboxGroup>
        </Col>
      </Row>
      <!-- 单选题选项 -->
      <Row
        v-if="
          question.item.question_type == 'multiple choice' &&
          question.item.is_multiple_answers === false
        "
      >
        <Col span="18">
          <Radio-group v-model="singleChoiceAnswer" vertical>
            <Radio
              v-for="option in question.item.options_list"
              :key="option.key + question.id"
              :label="option.key"
            >
              <span>{{ option.key + ". " }}</span>
              <span v-html="renderKatex(option.body)"></span>
              <div>
                <img
                  class="option-img"
                  v-if="option.image && option.imageUrl"
                  v-bind:src="option.imageUrl"
                />
                <img
                  class="option-img"
                  v-if="option.image && !option.imageUrl"
                  v-bind:src="option.image"
                />
              </div>
            </Radio>
          </Radio-group>
        </Col>
      </Row>
    </div>

    <Row type="flex" justify="space-between">
      <Col>
        <Button
          type="info"
          @click="moveQuestion(false)"
          v-if="currentQuestion != 1"
          >&lt; Prev</Button
        >
        <span>{{ " " }}</span>
        <Button
          type="info"
          @click="moveQuestion(true)"
          v-if="currentQuestion < totalQuestion"
          >Next &gt;
        </Button>
      </Col>
    </Row>

    <div class="sample-answer">
      <Collapse v-model="correctAnswer" simple>
        <Panel name="1">
          参考答案
          <p
            slot="content"
            v-if="question.item.question_type == 'multiple choice'"
          >
            <span v-for="answer in question.item.correct_answers" :key="answer">
              {{ answer + " " }}
            </span>
          </p>
          <div slot="content" v-else>
            <mavon-editor
              class="answer-editor"
              v-model="question.item.correct_answers[0]"
              :subfield="descViewOption.subfield"
              :defaultOpen="descViewOption.defaultOpen"
              :toolbarsFlag="descViewOption.toolbarsFlag"
              :editable="descViewOption.editable"
              :scrollStyle="descViewOption.scrollStyle"
              :boxShadow="descViewOption.boxShadow"
              :previewBackground="descViewOption.previewBackground"
              :ishljs="true"
              :codeStyle="descViewOption.codeStyle"
            ></mavon-editor>
          </div>
        </Panel>
      </Collapse>
    </div>

    <Modal v-model="doubleCheck" @on-ok="ok" @on-cancel="cancel">
      Review模式下将默认展开所有题目的答案，是否确认开启？
    </Modal>

    <Modal v-model="showResult">
      <p>
        选择题: {{ this.correctQuestionNumber }} /
        {{ this.wrongQuestionNumber + this.correctQuestionNumber }}. 正确率：
        <span v-if="this.wrongQuestionNumber + this.correctQuestionNumber != 0">
          {{
            parseFloat(
              (this.correctQuestionNumber /
                (this.wrongQuestionNumber + this.correctQuestionNumber)) *
                100
            ).toFixed(2)
          }}
        </span>
        <span v-else>
          {{ 0 }}
        </span>
        %
      </p>

      <p>有{{ this.shortAnswerQuestionNumber }} 道简答题需要您自行判断对错.</p>
    </Modal>
  </div>
</template>

<script>
import { CellGroup } from "view-design";
import { mapActions, mapState } from "vuex";
import { renderLatex } from "../../lib/tools.js";
export default {
  props: [
    //题目信息
    "question",
    //总题数
    "totalQuestion",
    //当前题目编号
    "currentQuestion",

    "questionIdList",

    "newReview",

    "saveQueAnswer"
  ],
  data() {
    return {
      tempAnswerList: [],
      correctAnswer: "",
      descViewOption: {
        subfield: false, // 单双栏模式
        defaultOpen: "preview", //edit： 默认展示编辑区域 ， preview： 默认展示预览区域
        editable: false,
        toolbarsFlag: false,
        scrollStyle: true,
        boxShadow: false,
        previewBackground: "#fff",
        codeStyle: "atom-one-light",
        imageClick: false
      },
      doubleCheck: false,
      showResult: false
    };
  },

  watch: {
    id(newVal, oldVal) {
      if (this.tempAnswerList.length > 0) {
        this.$emit("finishQuestion", oldVal);
        this.axios.post(
          `/api/campus/${this.$route.query.campus}/faculty/${this.$route.query.faculty}/unit/${this.$route.query.course}/question/finished`,
          [oldVal]
        );
        this.$store.commit("QuestionAnswer/setData", {
          id: oldVal,
          answer: this.answerList,
          correct:
            this.question.item.question_type === "short answer"
              ? "short answer"
              : this.sameArray(
                  this.tempAnswerList,
                  this.questionsList.questions[oldVal].item.correct_answers
                )
        });
      }
      this.tempAnswerList = [];
      if (this.review) {
        this.correctAnswer = "1";
      } else {
        this.correctAnswer = "";
      }
    },
    newReview() {
      this.submit(this.newReview);
    }
  },

  methods: {
    moveQuestion(val) {
      if (this.answerList.length > 0) {
        this.$emit("finishQuestion", this.question.id);
        this.axios.post(
          `/api/campus/${this.$route.query.campus}/faculty/${this.$route.query.faculty}/unit/${this.$route.query.course}/question/finished`,
          [this.question.id]
        );
        this.$store.commit("QuestionAnswer/setData", {
          id: this.question.id,
          answer: this.answerList,
          correct:
            this.question.item.question_type === "short answer"
              ? "short answer"
              : this.sameArray(
                  this.answerList,
                  this.question.item.correct_answers
                )
        });
      }

      let newQuestionId;
      if (val === true) {
        newQuestionId =
          this.questionIdList[
            this.questionIdList.indexOf(this.question.id) + 1
          ];
      } else {
        newQuestionId =
          this.questionIdList[
            this.questionIdList.indexOf(this.question.id) - 1
          ];
      }
      this.$emit("changeCurrentId", newQuestionId);
      this.$router.replace({
        query: {
          campus: this.campus,
          faculty: this.$route.query.faculty,
          course: this.$route.query.course,
          id: newQuestionId
        }
      });

      this.answerList = [];
      this.tempAnswerList = [];
    },

    submit(val) {
      if (val === true) {
        if (this.answerList.length > 0) {
          this.$emit("finishQuestion", this.question.id);
          this.axios.post(
            `/api/campus/${this.$route.query.campus}/faculty/${this.$route.query.faculty}/unit/${this.$route.query.course}/question/finished`,
            [this.question.id]
          );
          this.$store.commit("QuestionAnswer/setData", {
            id: this.question.id,
            answer: this.answerList,
            correct:
              this.question.item.question_type === "short answer"
                ? "short answer"
                : this.sameArray(
                    this.answerList,
                    this.question.item.correct_answers
                  )
          });
        }
      }

      if (this.review) {
        this.correctAnswer = "1";
        this.showResult = true;
      } else {
        this.correctAnswer = "";
      }
    },

    ok() {
      this.review = false;
      this.correctAnswer = "1";
      this.$emit("changeReview", true);
      this.$Message.info("开启review模式");
      this.showResult = true;
    },

    cancel() {
      //Do nothing
    },

    renderKatex(text) {
      return renderLatex(text);
    },
    sameArray: function (arr1, arr2) {
      let flag = true;
      if (arr1.length !== arr2.length) {
        flag = false;
      } else {
        arr1.forEach(item => {
          if (arr2.indexOf(item) === -1) {
            flag = false;
          }
        });
      }
      return flag;
    }
  },

  computed: {
    ...mapState({
      questionAnswer: state => state.QuestionAnswer.data,
      questionsList: state => state.QuestionsList
    }),

    id: function () {
      return this.$route.query.id;
    },

    answerList: {
      get: function () {
        if (this.tempAnswerList.length != 0) {
          return this.tempAnswerList;
        }
        if (Object.keys(this.questionAnswer).includes(this.id)) {
          return this.questionAnswer[this.id][0];
        }
        return [];
      },
      set: function (val) {
        this.tempAnswerList = val;
      }
    },

    singleChoiceAnswer: {
      get: function () {
        return this.answerList[0];
      },
      set: function (val) {
        const newList = [val];
        this.tempAnswerList = newList;
      }
    },

    correctQuestionNumber: function () {
      let num = 0;
      if (this.review) {
        for (const key in this.questionAnswer) {
          if (this.questionAnswer[key][1] === true) {
            num = num + 1;
          }
        }
      }
      return num;
    },

    wrongQuestionNumber: function () {
      let num = 0;
      if (this.review) {
        for (const key in this.questionAnswer) {
          if (this.questionAnswer[key][1] === false) {
            num = num + 1;
          }
        }
      }
      return num;
    },

    shortAnswerQuestionNumber: function () {
      let num = 0;
      if (this.review) {
        for (const key in this.questionAnswer) {
          if (this.questionAnswer[key][1] === "short answer") {
            num = num + 1;
          }
        }
      }
      return num;
    },

    review: {
      get: function () {
        return this.newReview;
      },
      set: function (val) {
        this.submit(val);
      }
    }
  }
};
</script>
