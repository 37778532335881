<style lang="less" scoped>
@import "./new-question.less";
</style>

<template>
  <div>
    <Radio-group class="title" v-model="type">
      题目类型：
      <Radio label="single choice"> 单选题 </Radio>
      <Radio label="multiple choice"> 多选题 </Radio>
      <Radio label="short answer"> 简答题 </Radio>
    </Radio-group>
    <mavon-editor
      v-model="queBody"
      :toolbars="descEditorOption.toolbars"
      :ishljs="true"
      :codeStyle="descEditorOption.codeStyle"
      :boxShadow="descEditorOption.boxShadow"
      :previewBackground="descEditorOption.previewBackground"
      style="z-index: 1"
      @imgAdd="handleMavonImgAdd"
      @imgDel="handleMavonImgDel"
    />

    <div class="options" v-if="type !== 'short answer'">
      <p>选项：</p>
      <ol class="options-list">
        <li class="option" v-for="item in optionsList" :key="item.key">
          <Input type="text" placeholder="暂无选项" v-model="item.body">
            <span slot="prepend">{{ item.key }}</span>
            <Button
              slot="append"
              icon="ios-trash"
              @click="delOption(item.key)"
            ></Button>
          </Input>

          <!-- Latex预览 -->
          <p dis-hover v-if="isLatex(item.body)" class="option-latex">
            <span v-html="renderKatex(item.body)"></span>
          </p>

          <!-- 选项图片预览 -->
          <img
            class="option-img"
            v-if="item.image && item.imageUrl"
            v-bind:src="item.imageUrl"
          />
          <img
            class="option-img"
            v-if="item.image && !item.imageUrl"
            v-bind:src="item.image"
          />
        </li>
      </ol>
      <Input
        type="text"
        class="option"
        placeholder="输入内容后，点击右侧 √ 保存选项"
        v-model="newOption"
        @keyup.enter.native="addOption(newOption)"
      >
        <span slot="prepend">{{
          String.fromCharCode(65 + optionsList.length)
        }}</span>
        <div slot="append" style="display: flex">
          <Upload
            action="#"
            :beforeUpload="beforeUpload"
            :on-exceeded-size="handleMaxSize"
            :max-size="coverUploader.maxSize"
          >
            <Button icon="md-image" class="btn-image"> </Button>
          </Upload>

          <div>
            <Button icon="md-add" @click="addOption(newOption)"> </Button>
          </div>
        </div>
      </Input>
      <!-- 新选项图片预览 -->
      <img class="option-img" v-if="img.length !== 0" v-bind:src="img" />
    </div>

    <!-- 简答题答案 -->
    <div class="answer" v-if="this.type === 'short answer'">
      <span>参考答案：</span>
      <!-- <Input
        class="ans-input"
        type="textarea"
        placeholder="暂无答案"
        v-model="answerList[0]"
        :rows="6"
      /> -->
      <mavon-editor
        style="margin-top: 15px"
        class="question-body-editor"
        v-model="answerList[0]"
        :toolbars="descEditorAns.toolbars"
        :ishljs="true"
        :codeStyle="descEditorAns.codeStyle"
        :boxShadow="descEditorAns.boxShadow"
        :previewBackground="descEditorAns.previewBackground"
      />
    </div>

    <!-- 单选题答案 -->
    <div class="answer" v-if="this.type === 'single choice'">
      <Radio-group v-model="answerList[0]" class="labels">
        <span>参考答案：</span>
        <Radio v-for="item in optionsList" :key="item.key" :label="item.key">
        </Radio>
      </Radio-group>
    </div>

    <!-- 多选题答案 -->
    <div class="answer" v-if="this.type === 'multiple choice'">
      <span>参考答案：</span>
      <CheckboxGroup v-model="answerList" class="labels">
        <Checkbox v-for="item in optionsList" :key="item.key" :label="item.key">
        </Checkbox>
      </CheckboxGroup>
    </div>

    <div class="add-cancel-button">
      <Button @click="cancelCreation()">取消</Button>
      <Button type="primary" @click="saveCreation()" class="check-button"
        >确认新建</Button
      >
    </div>
  </div>
</template>

<script>
import { getAuthUrl, getUploadFormData } from "../../lib/attachment";
import { renderLatex } from "../../lib/tools.js";
//选项的key从A开始，A的ASCII码为65
const OPTION_KEY = 65;
//选项最多为10个
const MAX_OPTIONS = 10;
export default {
  props: [
    // courseCode,
  ],
  data() {
    return {
      // 上传图片地址
      img: "",
      imgFilename: "",
      imgUrl: "",

      queBody: "",
      optionsList: [],
      newOption: "",
      answerList: [],
      type: "single choice",
      //markdown config - body
      descEditorOption: {
        toolbars: {
          bold: true, // 粗体
          italic: true, // 斜体
          header: true, // 标题
          underline: true, // 下划线
          mark: true, // 标记
          superscript: true, // 上角标
          quote: true, // 引用
          ol: true, // 有序列表
          link: true, // 链接
          imagelink: true, // 图片链接
          help: true, // 帮助
          code: true, // code
          subfield: true, // 是否需要分栏
          fullscreen: true, // 全屏编辑
          readmodel: true, // 沉浸式阅读
          /* 1.3.5 */
          undo: true, // 上一步
          redo: true,
          trash: false, // 清空
          save: false, // 保存（触发events中的save事件）
          /* 1.4.2 */
          navigation: false, // 导航目录
          table: false
        }
      },
      //markdown config - answer
      descEditorAns: {
        toolbars: {
          bold: true, // 粗体
          italic: true, // 斜体
          header: true, // 标题
          underline: true, // 下划线
          mark: true, // 标记
          superscript: true, // 上角标
          quote: true, // 引用
          ol: true, // 有序列表
          link: true, // 链接
          imagelink: false, // 图片链接
          help: true, // 帮助
          code: true, // code
          subfield: true, // 是否需要分栏
          fullscreen: true, // 全屏编辑
          readmodel: true, // 沉浸式阅读
          /* 1.3.5 */
          undo: true, // 上一步
          redo: true,
          trash: false, // 清空
          save: false, // 保存（触发events中的save事件）
          /* 1.4.2 */
          navigation: false, // 导航目录
          table: false
        }
      },
      //image upload config
      coverUploader: {
        supportSuffix: [".jpg", ".jpeg", ".png"],
        filename: "",
        maxSize: 10240,
        percent: 0,
        url: "",
        done: false,
        imageUploadForm: {
          name: "",
          key: "",
          policy: "",
          OSSAccessKeyId: "",
          success_action_status: 200,
          callback: "",
          signature: "",
          file: new Image(),
          "x-oss-forbid-overwrite": "true",
          "Access-Control-Allow-Origin": "*"
        }
      }
    };
  },
  methods: {
    delOption: function (key) {
      this.optionsList = this.optionsList.filter(o => o.key != key);
      //更新options的key
      for (let i = 0; i < this.optionsList.length; i++) {
        //key从A开始
        this.optionsList[i].key = String.fromCharCode(OPTION_KEY + i);
      }
    },
    //添加option
    addOption: function (content) {
      //最多不能有10个选项
      if (this.optionsList.length <= MAX_OPTIONS) {
        if (content.length === 0) {
          this.$Message.warning("选项不能为空！");
          return "";
        }
        const newOptionKey = String.fromCharCode(
          OPTION_KEY + this.optionsList.length
        );
        let newOptionCon;
        if (this.imgFilename.length > 0) {
          newOptionCon = {
            key: newOptionKey,
            body: content,
            image: this.imgFilename,
            imageUrl: this.imgUrl
          };
        } else {
          newOptionCon = {
            key: newOptionKey,
            body: content
          };
        }
        this.optionsList.push(newOptionCon);
        this.newOption = "";
        this.imgFilename = "";
        this.imageUrl = "";
        this.img = "";
      } else this.$Message.warning("选项数量不能超过10个！");
    },
    //确认新建
    saveCreation: function () {
      //判断多选和单选
      let is_multiple_answers = false;
      let questionType;
      let answer;

      //表单控制

      if (this.type.length === 0) {
        this.$Message.warning("请选择题目类型！");
        return;
      }
      if (this.queBody.length === 0) {
        this.$Message.warning("请输入题干！");
        return;
      }
      //多选题
      if (this.type === "multiple choice") {
        is_multiple_answers = true;
        questionType = "multiple choice";
        if (this.optionsList.length === 0) {
          this.$Message.warning("请设置题目选项！");
          return;
        }
        if (this.answerList.length === 0) {
          this.$Message.warning("请设置参考答案！");
          return;
        }
        answer = this.answerList;

        //单选题
      } else if (this.type === "single choice") {
        is_multiple_answers = false;
        questionType = "multiple choice";
        if (this.optionsList.length === 0) {
          this.$Message.warning("请设置题目选项！");
          return;
        }
        if (this.answerList.length === 0) {
          this.$Message.warning("请设置参考答案！");
          return;
        }
        answer = this.answerList;

        //简答题
      } else {
        questionType = "short answer";
        answer = this.answerList;
      }
      // format optionList
      const newOptionList = [];
      for (let i = 0; i < this.optionsList.length; i++) {
        const option = {
          key: this.optionsList[i].key,
          body: this.optionsList[i].body,
          image: this.optionsList[i].image
        };
        newOptionList.push(option);
      }

      const time = new Date().toISOString();
      const question = {
        question_type: questionType, //题目类型 short answer | multiple choice
        create_time: time,
        is_multiple_answers: is_multiple_answers, //是否拥有多个答案
        question_body: this.queBody, //题干
        options_list: newOptionList,
        correct_answers: answer //答案List，如果只有一个答案，长度应为1
      };
      this.$emit("saveQuestion", question);
    },

    //取消新建
    cancelCreation: function () {
      this.$emit("cancelCreation");
    },
    //Latex
    isLatex(text) {
      return text.split("$").length >= 3;
    },
    renderKatex(text) {
      return renderLatex(text);
    },
    //上传文件最大不超过10m
    handleMaxSize(file) {
      this.$Notice.warning({
        title: "Exceeding file size limit",
        desc: "File  " + file.name + " is too large, no more than 10M."
      });
    },
    //上传选项图片
    beforeUpload(file) {
      const filename = file.name;
      this.coverUploader.filename = filename;
      //判断后缀
      if (
        !this.coverUploader.supportSuffix.includes(this.get_suffix(filename))
      ) {
        this.$Notice.warning({
          title: "不支持的文件格式",
          desc: "该文件格式不支持，请选择JPEG，JPG，PNG格式文件"
        });
        return false;
      }
      //获取auth
      this.axios
        .get(`/api/attachments/questionOptionImages/uploadAuth`)
        .then(resp => {
          const baseInfo = resp.data;
          const key = this.calculate_key(this.get_suffix(filename), baseInfo);
          //Build the upload form
          const form = new FormData();
          form.append("name", filename);
          form.append("key", key);
          form.append("policy", baseInfo.policy);
          form.append("OSSAccessKeyId", baseInfo.accessid);
          form.append("callback", baseInfo.callback);
          form.append("signature", baseInfo.signature);
          form.append("file", file);

          this.axios
            .post(`${baseInfo.host}`, form, {
              onUploadProgress: evt => {
                //进度条
                let percent = 0;
                if (evt.lengthComputable) {
                  percent = Math.floor((evt.loaded / event.total) * 100);
                  if (percent > 100) {
                    percent = 100;
                  }
                }
                this.coverUploader.percent = percent;
              }
            })
            .then(resp => {
              if (resp.status == 203) {
                this.$Message.error(`上传失败！不支持的文件格式`);
                this.coverUploader.filename = "上传失败";
                this.coverUploader.percent = 0;
              } else {
                this.$Message.success("上传成功！");
                // this.diskInfo.image_filename = resp.data.filename;
                this.coverUploader.done = true;
                this.img = resp.data.image;
                this.imgFilename = resp.data.filename;
                this.imgUrl = resp.data.image;
              }
            })
            .catch(error => {
              // this.error = error
              this.$Message.error(`上传失败！错误信息: ${error}`);
              this.coverUploader.percent = 0;
              this.coverUploader.filename = "上传失败";
            });
        });

      return false;
    },
    // 获取上传图片的后缀
    get_suffix(filename) {
      const pos = filename.lastIndexOf(".");
      let suffix = "";
      if (pos != -1) {
        suffix = filename.substring(pos);
      }
      return suffix;
    },
    //拼接key
    calculate_key(suffix, baseInfo) {
      return baseInfo.dir + baseInfo.file_uuid + suffix;
    },
    handleMavonImgAdd(pos, file) {
      this.$Loading.start();
      this.axios
        .get(getAuthUrl("question-body-image"))
        .then(resp => {
          const form = getUploadFormData(resp.data, file);
          return this.axios.post(resp.data.host, form);
        })
        .then(resp => {
          if (resp.status === 203) {
            this.$Message.error(`上传失败！不支持的文件格式`);
          } else {
            this.$Message.success("上传成功！");
            this.$Loading.finish();
            // Bind img and course
            // this.axios.post(
            //   `api/course/${this.$route.query.course}/question/${this.$route.query.id}/images`,
            //   { filename: resp.data.filename }
            // );
            this.handleMavonReplaceImgUrl(pos, resp.data.filename);
          }
        })
        .catch(error => {
          this.$Loading.error();
          this.$Message.error(`上传失败！错误信息: ${error}`);
        });
    },
    handleMavonReplaceImgUrl(pos, filename) {
      const url = `api/redirect/question/image/${filename.split("/")[2]}`;
      // $vm.$img2Url();
      const content = this.queBody;
      // Replace Img url ![...](xxx) -> ![...](url)
      const oStr = `(${pos})`;
      const nStr = `(${url})`;
      const index = content.indexOf(oStr);
      const str = content.replace(oStr, "");
      const insertStr = (source, start, newStr) => {
        return source.slice(0, start) + newStr + source.slice(start);
      };
      this.queBody = insertStr(str, index, nStr);
    },
    handleMavonImgDel() {
      //Do nothing
    }
  }
};
</script>
