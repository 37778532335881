export function renderLatex(text) {
  const sign = [];
  for (let i = 0; i < text.length; i++) {
    if (text[i] == "$") {
      sign.push(i);
      if (sign.length == 2) {
        if (sign[1] - sign[0] > 1) {
          const left = text.slice(0, sign[0]);
          // eslint-disable-next-line no-undef
          const latex = katex.renderToString(text.slice(sign[0] + 1, sign[1]), {
            throwOnError: false,
            displayMode: false
          });
          const right = renderLatex(text.slice(sign[1] + 1, text.length));
          return left + latex + right;
        }
      } else if (sign.length == 4) {
        if (sign[3] - sign[2] == 1) {
          const left = text.slice(0, sign[0]);
          // eslint-disable-next-line no-undef
          const latex = katex.renderToString(text.slice(sign[1] + 1, sign[2]), {
            throwOnError: false,
            displayMode: true
          });
          const right = renderLatex(text.slice(sign[3] + 1, text.length));
          return left + latex + right;
        } else {
          const left = text.slice(0, sign[0]);
          const right = renderLatex(text.slice(sign[1] + 1, text.length));
          return left + right;
        }
      }
    }
  }
  if (sign.length == 2) {
    const left = text.slice(0, sign[0]);
    const right = renderLatex(text.slice(sign[1] + 1, text.length));
    return left + right;
  }
  return text;
}
