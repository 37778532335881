<style lang="less" scoped>
@import "./que-stem-manage.less";
</style>

<template>
  <div>
    <Radio-group class="title" v-model="queType" v-if="!creatingQuestion">
      题目类型：
      <Radio label="single choice" :disabled="!creatingQuestion">
        单选题
      </Radio>
      <Radio label="multiple choice" :disabled="!creatingQuestion">
        多选题
      </Radio>
      <Radio label="short answer" :disabled="!creatingQuestion"> 简答题 </Radio>

      <p v-if="!creatingQuestion" class="title-count">
        题目：{{ currentIndex }}/{{ totalQuestions }}
      </p>
    </Radio-group>

    <!-- 题干 -->
    <div v-if="!creatingQuestion">
      <mavon-editor
        v-model="queBody"
        class="question-body-editor"
        :toolbars="descEditorOption.toolbars"
        :ishljs="true"
        :codeStyle="descEditorOption.codeStyle"
        :boxShadow="descEditorOption.boxShadow"
        :imageClick="descEditorOption.imageClick"
        :previewBackground="descEditorOption.previewBackground"
        @imgAdd="handleMavonImgAdd"
        @imgDel="handleMavonImgDel"
      />

      <div class="options" v-if="this.queType !== 'short answer'">
        <p>选项：</p>
        <ol class="options-list" v-if="optionsList.length > 0">
          <li class="option" v-for="item in optionsList" :key="item.key">
            <Input type="text" placeholder="暂无题目" v-model="item.body">
              <div slot="prepend" class="option-prepend">
                <span>{{ item.key }}</span>
              </div>
              <Button
                slot="append"
                icon="ios-trash"
                @click="delOption(item.key)"
              ></Button>
            </Input>

            <!-- Latex预览 -->
            <p dis-hover v-if="isLatex(item.body)" class="option-latex">
              <span v-html="renderKatex(item.body)"></span>
            </p>

            <!-- 选项图片预览 -->
            <img
              class="option-img"
              v-if="item.image && item.imageUrl"
              v-bind:src="item.imageUrl"
            />
            <img
              class="option-img"
              v-if="item.image && !item.imageUrl"
              v-bind:src="item.image"
            />
          </li>
        </ol>
        <Input
          type="text"
          class="option"
          placeholder="输入内容后，点击右侧 + 保存选项"
          v-model="newOption"
          @keyup.enter.native="addOption(newOption)"
        >
          <div slot="prepend" class="option-prepend">
            <span>{{
              optionsList.length > 0
                ? String.fromCharCode(
                    optionsList[optionsList.length - 1].key.charCodeAt() + 1
                  )
                : "A"
            }}</span>
          </div>

          <div slot="append" style="display: flex">
            <Upload
              action="#"
              :beforeUpload="beforeUpload"
              :on-exceeded-size="handleMaxSize"
              :max-size="coverUploader.maxSize"
            >
              <Button icon="md-image" class="btn-image"> </Button>
            </Upload>

            <div>
              <Button @click="addOption(newOption)"
                ><Icon type="md-add"
              /></Button>
            </div>
          </div>
        </Input>

        <!-- 新选项图片预览 -->
        <img class="option-img" v-if="img.length !== 0" v-bind:src="img" />
      </div>
      <!-- 简答题答案 -->
      <div class="answer" v-if="this.queType === 'short answer'">
        <span>参考答案：</span>
        <mavon-editor
          style="margin-top: 15px"
          class="question-body-editor"
          v-model="answerList[0]"
          :toolbars="descEditorAns.toolbars"
          :ishljs="true"
          :codeStyle="descEditorAns.codeStyle"
          :boxShadow="descEditorAns.boxShadow"
          :previewBackground="descEditorAns.previewBackground"
        />
      </div>

      <!-- 单选题答案 -->
      <div class="answer" v-if="this.queType === 'single choice'">
        <Radio-group v-model="answerList[0]" class="labels">
          <span>参考答案：</span>
          <Radio v-for="item in optionsList" :key="item.key" :label="item.key">
          </Radio>
        </Radio-group>
      </div>

      <!-- 多选题答案 -->
      <div class="answer" v-if="this.queType === 'multiple choice'">
        <span>参考答案：</span>
        <CheckboxGroup v-model="answerList" class="labels">
          <Checkbox
            v-for="item in optionsList"
            :key="item.key"
            :label="item.key"
          >
          </Checkbox>
        </CheckboxGroup>
      </div>

      <Row type="flex" justify="space-between" class="code-row-bg">
        <Col>
          <Button
            v-if="this.creatingQuestion === false"
            type="success"
            @click="saveQuestion()"
            >保存修改</Button
          >
        </Col>

        <Col>
          <Button
            v-if="this.creatingQuestion === false"
            type="error"
            @click="delCheck()"
            >删除本题</Button
          >
        </Col>
      </Row>
    </div>

    <!-- 新题目 -->

    <new-question
      v-else
      @saveQuestion="saveNewQuestion($event)"
      @cancelCreation="cancelCreation"
    ></new-question>
  </div>
</template>

<script>
//选项的key从A开始，A的ASCII码为65
const OPTION_KEY = 65;
//选项最多为10个
const MAX_OPTIONS = 10;
import newQuestion from "../new-question/new-question.vue";
import { renderLatex } from "../../lib/tools.js";
import { getAuthUrl, getUploadFormData } from "../../lib/attachment";
export default {
  components: {
    newQuestion
  },
  props: [
    //题目信息
    "questions",
    //课程编号
    "courseCode",
    //总题数
    "totalQuestions",
    //当前题目编号
    "currentIndex",
    "creatingQuestion"
  ],
  data() {
    return {
      // 上传图片地址
      img: "",
      imgFilename: "",
      imgUrl: "",
      newOption: "",
      //加载动画
      spinShow: false,
      //markdown config - body
      descEditorOption: {
        toolbars: {
          bold: true, // 粗体
          italic: true, // 斜体
          header: true, // 标题
          underline: true, // 下划线
          mark: true, // 标记
          superscript: true, // 上角标
          quote: true, // 引用
          ol: true, // 有序列表
          link: true, // 链接
          imagelink: true, // 图片链接
          help: true, // 帮助
          code: true, // code
          subfield: true, // 是否需要分栏
          fullscreen: true, // 全屏编辑
          readmodel: true, // 沉浸式阅读
          /* 1.3.5 */
          undo: true, // 上一步
          redo: true,
          trash: false, // 清空
          save: false, // 保存（触发events中的save事件）
          /* 1.4.2 */
          navigation: false, // 导航目录
          table: false
        }
      },
      //markdown config - answer
      descEditorAns: {
        toolbars: {
          bold: true, // 粗体
          italic: true, // 斜体
          header: true, // 标题
          underline: true, // 下划线
          mark: true, // 标记
          superscript: true, // 上角标
          quote: true, // 引用
          ol: true, // 有序列表
          link: true, // 链接
          imagelink: false, // 图片链接
          help: true, // 帮助
          code: true, // code
          subfield: true, // 是否需要分栏
          fullscreen: true, // 全屏编辑
          readmodel: true, // 沉浸式阅读
          /* 1.3.5 */
          undo: true, // 上一步
          redo: true,
          trash: false, // 清空
          save: false, // 保存（触发events中的save事件）
          /* 1.4.2 */
          navigation: false, // 导航目录
          table: false
        }
      },
      //image upload config
      coverUploader: {
        supportSuffix: [".jpg", ".jpeg", ".png"],
        filename: "",
        maxSize: 10240,
        percent: 0,
        url: "",
        done: false,
        imageUploadForm: {
          name: "",
          key: "",
          policy: "",
          OSSAccessKeyId: "",
          success_action_status: 200,
          callback: "",
          signature: "",
          file: new Image(),
          "x-oss-forbid-overwrite": "true",
          "Access-Control-Allow-Origin": "*"
        }
      }
    };
  },
  methods: {
    //上传文件最大不超过10m
    handleMaxSize(file) {
      this.$Notice.warning({
        title: "Exceeding file size limit",
        desc: "File  " + file.name + " is too large, no more than 10M."
      });
    },
    //上传选项图片
    beforeUpload(file) {
      const filename = file.name;
      this.coverUploader.filename = filename;
      //判断后缀
      if (
        !this.coverUploader.supportSuffix.includes(this.get_suffix(filename))
      ) {
        this.$Notice.warning({
          title: "不支持的文件格式",
          desc: "该文件格式不支持，请选择JPEG，JPG，PNG格式文件"
        });
        return false;
      }
      //获取auth
      this.axios
        .get(`/api/attachments/questionOptionImages/uploadAuth`)
        .then(resp => {
          const baseInfo = resp.data;
          const key = this.calculate_key(this.get_suffix(filename), baseInfo);
          //Build the upload form
          const form = new FormData();
          form.append("name", filename);
          form.append("key", key);
          form.append("policy", baseInfo.policy);
          form.append("OSSAccessKeyId", baseInfo.accessid);
          form.append("callback", baseInfo.callback);
          form.append("signature", baseInfo.signature);
          form.append("file", file);

          this.axios
            .post(`${baseInfo.host}`, form, {
              onUploadProgress: evt => {
                //进度条
                let percent = 0;
                if (evt.lengthComputable) {
                  percent = Math.floor((evt.loaded / event.total) * 100);
                  if (percent > 100) {
                    percent = 100;
                  }
                }
                this.coverUploader.percent = percent;
              }
            })
            .then(resp => {
              if (resp.status == 203) {
                this.$Message.error(`上传失败！不支持的文件格式`);
                this.coverUploader.filename = "上传失败";
                this.coverUploader.percent = 0;
              } else {
                this.$Message.success("上传成功！");
                // this.diskInfo.image_filename = resp.data.filename;
                this.coverUploader.done = true;
                this.img = resp.data.image;
                this.imgFilename = resp.data.filename;
                this.imgUrl = resp.data.image;
              }
            })
            .catch(error => {
              // this.error = error
              this.$Message.error(`上传失败！错误信息: ${error}`);
              this.coverUploader.percent = 0;
              this.coverUploader.filename = "上传失败";
            });
        });

      return false;
    },
    // 获取上传图片的后缀
    get_suffix(filename) {
      const pos = filename.lastIndexOf(".");
      let suffix = "";
      if (pos != -1) {
        suffix = filename.substring(pos);
      }
      return suffix;
    },
    //拼接key
    calculate_key(suffix, baseInfo) {
      return baseInfo.dir + baseInfo.file_uuid + suffix;
    },
    //删除选项
    delOption: function (key) {
      this.optionsList = this.optionsList.filter(o => o.key != key);
      //更新options的key
      for (let i = 0; i < this.optionsList.length; i++) {
        //key从A开始
        this.optionsList[i].key = String.fromCharCode(OPTION_KEY + i);
      }
      this.answerList = [];
    },

    //添加option
    addOption: function (content) {
      //最多不能有10个选项
      if (this.optionsList.length <= MAX_OPTIONS) {
        if (content.length === 0) {
          this.$Message.warning("选项不能为空！");
          return "";
        }
        const newOptionKey = String.fromCharCode(
          OPTION_KEY + this.optionsList.length
        );
        let newOptionCon;
        if (this.imgFilename.length > 0) {
          newOptionCon = {
            key: newOptionKey,
            body: content,
            image: this.imgFilename,
            imageUrl: this.imgUrl
          };
        } else {
          newOptionCon = {
            key: newOptionKey,
            body: content
          };
        }
        this.optionsList.push(newOptionCon);
        this.newOption = "";
        this.imgFilename = "";
        this.imageUrl = "";
        this.img = "";
      } else this.$Message.warning("选项数量不能超过10个！");
    },

    //保存修改
    saveQuestion: function () {
      //表单控制
      if (!this.queBody) {
        this.$Message.warning("请输入题干！");
        return "";
      }
      if (this.queType !== "short answer") {
        if (!this.optionsList[0]) {
          this.$Message.warning("请输入题目选项！");
          return "";
        }
      }
      if (!this.answerList[0]) {
        this.$Message.warning("请输入参考答案！");
        return "";
      }

      //简答题
      if (this.queType === "short answer") {
        const time = new Date().toISOString();
        const question = {
          question_id: this.questionId,
          course_code: this.courseCode,
          question_type: "short answer",
          //"create_time": time,
          question_body: this.queBody,
          image: null,
          correct_answers: [this.answerList[0]]
        };
        //修改后端数据
        this.$Loading.start();
        this.axios
          .put(`/api/question/${this.questionId}`, question)
          .then(res => {
            this.$Loading.finish();
            this.$Message.success("修改题目信息成功！");
          })
          .catch(error => {
            this.$Loading.error();
            this.$Message.error("修改题目信息失败！");
          });
        //修改vuex数据
        this.$store.commit("changeQuestion", {
          id: this.questionId,
          item: question
        });
      } else {
        // 选择题
        let is_multiple_answers;
        //判断单选多选
        if (this.queType === "multiple choice") {
          is_multiple_answers = true;
        } else {
          is_multiple_answers = false;
        }
        const newOptionList = [];
        let option;
        for (let i = 0; i < this.optionsList.length; i++) {
          //if it is the filename of image
          if (
            this.optionsList[i].image &&
            this.optionsList[i].image.charAt(0) === "i"
          ) {
            option = {
              key: this.optionsList[i].key,
              body: this.optionsList[i].body,
              image: this.optionsList[i].image
            };
          } else {
            option = {
              key: this.optionsList[i].key,
              body: this.optionsList[i].body
            };
          }
          newOptionList.push(option);
        }

        const question = {
          question_id: this.questionId,
          question_type: "multiple choice", //题目类型 short answer | multiple choice
          is_multiple_answers: is_multiple_answers, //是否拥有多个答案
          question_body: this.queBody, //题干
          options_list: newOptionList,
          // image: "Not Implemented",
          correct_answers: this.answerList //答案List，如果只有一个答案，长度应为1
        };
        this.$Loading.start();
        this.axios
          .put(`/api/question/${this.questionId}`, question)
          .then(res => {
            //修改vuex数据
            this.$store.commit("changeQuestion", {
              id: this.questionId,
              item: res.data
            });
            this.$Loading.finish();
            this.$Message.success("修改题目信息成功！");
          })
          .catch(error => {
            this.$Loading.error();
            this.$Message.error("修改题目信息失败！");
          });
      }
    },

    //删除确认
    delCheck: function () {
      this.$Modal.confirm({
        title: "确定要删除该题吗？",
        onOk: () => {
          this.delQuestion();
        },
        onCancel: () => {
          this.$Message.info("取消删除！");
        }
      });
    },
    //删除题目
    delQuestion: function () {
      this.axios
        .delete(`/api/question/${this.questionId}`)
        .then(res => {
          this.$emit("delQuestion");
          this.$Message.success("删除题目信息成功！");
        })
        .catch(error => {
          this.$Message.error("删除题目信息失败！");
        });

      this.$store.commit("delQuestion");
    },

    //保存新建题目
    saveNewQuestion: function (question) {
      this.$Loading.start();
      this.axios
        .post(
          `/api/campus/${this.$route.query.campus}/faculty/${this.$route.query.faculty}/unit/${this.$route.query.course}/questions`,
          question
        )
        .then(res => {
          this.$Loading.finish();
          this.$store.commit("addQuestions", {
            id: res.data.question_id,
            item: res.data
          });
          this.$emit("newQuestion", res.data.question_id);
          this.$Message.success("新建题目成功！");
        })
        .catch(error => {
          this.$Loading.error();
          this.$Message.error("新建题目失败！");
        });
    },

    //取消新建
    cancelCreation: function () {
      this.$emit("cancelCreation");
    },
    isLatex(text) {
      return text.split("$").length >= 3;
    },
    renderKatex(text) {
      return renderLatex(text);
    },

    handleMavonImgAdd(pos, file) {
      this.$Loading.start();
      this.axios
        .get(getAuthUrl("question-body-image"))
        .then(resp => {
          const form = getUploadFormData(resp.data, file);
          return this.axios.post(resp.data.host, form);
        })
        .then(resp => {
          if (resp.status === 203) {
            this.$Message.error(`上传失败！不支持的文件格式`);
          } else {
            this.$Message.success("上传成功！");
            this.$Loading.finish();
            // Bind img and course
            // this.axios.post(
            //   `api/course/${this.$route.query.course}/question/${this.$route.query.id}/images`,
            //   { filename: resp.data.filename }
            // );
            this.handleMavonReplaceImgUrl(pos, resp.data.filename);
          }
        })
        .catch(error => {
          this.$Loading.error();
          this.$Message.error(`上传失败！错误信息: ${error}`);
        });
    },
    handleMavonReplaceImgUrl(pos, filename) {
      const url = `api/redirect/question/image/${filename.split("/")[2]}`;
      // $vm.$img2Url();
      const content = this.queBody;
      // Replace Img url ![...](xxx) -> ![...](url)
      const oStr = `(${pos})`;
      const nStr = `(${url})`;
      const index = content.indexOf(oStr);
      const str = content.replace(oStr, "");
      const insertStr = (source, start, newStr) => {
        return source.slice(0, start) + newStr + source.slice(start);
      };
      this.queBody = insertStr(str, index, nStr);
    },
    handleMavonImgDel() {
      //Do nothing
    }
  },
  computed: {
    queType: function () {
      //题目类型
      let queType;
      if (this.questions.item.question_type === "short answer") {
        queType = "short answer";
      } else if (this.questions.item.question_type === "multiple choice") {
        if (this.questions.item.is_multiple_answers === true) {
          queType = "multiple choice";
        } else {
          queType = "single choice";
        }
      } else {
        this.$Message.warning("题目类型错误！");
        queType = "";
      }
      return queType;
    },

    optionsList: {
      // getter
      get: function () {
        return this.questions.item.options_list;
      },
      set: function (newVal) {
        this.questions.item.options_list = newVal;
      }
    },
    answerList: {
      get: function () {
        return this.questions.item.correct_answers;
      },
      set: function (newVal) {
        this.questions.item.correct_answers = newVal;
      }
    },
    questionId: function () {
      return this.questions.item.question_id;
    },
    queBody: {
      get: function () {
        return this.questions.item.question_body;
      },
      set: function (newVal) {
        this.questions.item.question_body = newVal;
      }
    }
  }
};
</script>
